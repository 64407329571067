.brands-page{
    height: 100%;
    .brands-wrapper{
        display: flex;
        height: 100%;
        padding-top: 100px;
        @media screen and (max-width: 575px){
            padding-top: 90px;
        }
        aside.category-sidebar{
            width: 250px;
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            padding: 10px 0;
            border-right: 1px solid rgba(0,0,0,0.05);
            &::-webkit-scrollbar{
                display: none;
            }
            @media screen and (max-width: 767px){
                display: none;
            }
            .category-brands-link{
                padding: 10px 15px;
                font-size: 16px;
                font-weight: bold;
                display: block;
                &:hover{
                    background-color: rgba(0,0,0,0.03);
                }
                &.active{
                    background-color: rgba(0,0,0,0.03);
                }
            }
            ul.category-list{
                li.category-item{
                    a.category-link{
                        color: #444;
                        display: block;
                        padding: 5px 0;
                        padding-left: 30px;
                        &:hover{
                            color: $main-color;
                        }
                    }
                    div.category-label{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 10px 15px;
                        font-size: 16px;
                        font-weight: bold;
                        cursor: pointer;
                        color: $main-color;
                        &:hover{
                            background-color: rgba(0,0,0,0.03);
                        }
                        &.active{
                            &::after{
                                transform: rotate(180deg);
                            }
                        }
                        &::after{
                            content: "\f078";
                            font-family: "Font Awesome 5 Pro";
                            font-weight: normal;
                            font-size: 14px;
                            transition: transform 0.15s ease-in-out;
                        }
                    }
                }
            }
        }
        .brands-container{
            flex: 1;
            height: 100%;
            overflow: auto;
            padding: 20px 0 30px 0;
            .brands-content{
                width: calc(100% - 60px);
                max-width: 1200px;
                margin: 0 auto;
                @media screen and (max-width: 767px){
                    width: calc(100% - 30px);
                }
                .category-select{
                    display: none;
                    @media screen and (max-width: 767px){
                        display: block;
                    }
                }
                ul.product-list{
                    &.view-mode-grid{
                        display: flex;
                        align-items: stretch;
                        flex-wrap: wrap;
                        margin: 10px -15px 0 -15px;
                        li.product-item{
                            width: calc((100% - 120px) / 4);
                            margin: 0 15px 30px 15px;
                            box-shadow: 0 0 10px rgba(0,0,0,0.1);
                            position: relative;
                            @media screen and (max-width: 1199px){
                                width: calc((100% - 90px) / 3);
                            }
                            @media screen and (max-width: 991px){
                                width: calc((100% - 60px) / 2);
                            }
                            @media screen and (max-width: 767px){
                                width: calc((100% - 30px) / 3);
                                margin: 0 5px 10px 5px;
                            }
                            @media screen and (max-width: 575px){
                                width: calc((100% - 20px) / 2);
                            }
                            &.sold-out{
                                .product-container{
                                    .product-image{
                                        &::after{
                                            content: "Sold Out";
                                            position: absolute;
                                            top: 0;
                                            right: 0;
                                            bottom: 0;
                                            left: 0;
                                            background-color: #e9509880;
                                            color: #fff;
                                            font-size: 20px;
                                            font-weight: bold;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                        }
                                    }
                                    .product-content{
                                        .product-btn{
                                            > button{
                                                justify-content: center;
                                                &:after{ 
                                                    content: "Sold Out";
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &.limited-out{
                                .product-container{
                                    .product-image{
                                        &::after{
                                            content: "Limited Quantity";
                                            position: absolute;
                                            top: 0;
                                            right: 0;
                                            bottom: 0;
                                            left: 0;
                                            background-color: #e9509880;
                                            color: #fff;
                                            font-size: 18px;
                                            font-weight: bold;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                        }
                                    }
                                    .product-content{
                                        .product-btn{
                                            > button{
                                                justify-content: center;
                                                &:after{ 
                                                    content: "Limited Quantity";
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .product-container{
                                display: flex;
                                flex-direction: column;
                                height: 100%;
                                .product-image{
                                    width: 100%;
                                    padding-top: 100%;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: cover;
                                    background-image: url('../../images/web/no-product.png');
                                    position: relative;
                                }
                                .product-content{
                                    padding: 15px;
                                    flex: 1;
                                    display: flex;
                                    flex-direction: column;
                                    @media screen and (max-width: 415px){
                                        padding: 10px;
                                    }
                                    .product-info{
                                        flex: 1;
                                        .product-category{
                                            font-size: 12px;
                                            color: #969696;
                                        }
                                        .product-name{
                                            margin-top: 5px;
                                            @media screen and (max-width: 415px){
                                                font-size: 12px;
                                            }
                                        }
                                    }
                                    .product-btn{
                                        margin-top: 15px;
                                        align-self: flex-end;
                                        width: 100%;
                                        > button{
                                            border: 0;
                                            width: 100%;
                                            padding: 5px 15px;
                                            background-color: $main-color;
                                            color: #fff;
                                            font-size: 18px;
                                            font-weight: bold;
                                            border-radius: 50px;
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: center;
                                            @media screen and (max-width: 767px){
                                                font-size: 16px;
                                            }
                                            @media screen and (max-width: 415px){
                                                font-size: 13px;
                                            }
                                            &::after{
                                                content: "\ff0b";
                                            }
                                        }
                                    }
                                }
                            }
                            .product-brands {
                                width: 100%;
                                padding: 5px;
                                position: absolute;
                                top: 0;
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                .badge{
                                    max-width: 100%;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    & + .badge{
                                        margin-top: 5px;
                                    }
                                }
                            }
                        }
                    }
                    &.view-mode-list{
                        li.product-item{
                            display: flex;
                            flex-direction: column;
                            padding: 15px 0;
                            & + li.product-item{
                                border-top: 1px solid rgba(0,0,0,0.1);
                            }
                            &.sold-out{
                                .product-container{
                                    .product-image{
                                        &::after{
                                            content: "Sold Out";
                                            position: absolute;
                                            top: 0;
                                            right: 0;
                                            bottom: 0;
                                            left: 0;
                                            background-color: #e9509880;
                                            color: #fff;
                                            font-size: 12px;
                                            font-weight: bold;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                        }
                                    }
                                    .product-content{
                                        .product-btn{
                                            > button {
                                                justify-content: center;
                                                &:after{ 
                                                    content: "Sold Out";
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .product-container{
                                display: flex;
                                align-items: center;
                                .product-image{
                                    width: 60px;
                                    min-width: 60px;
                                    max-width: 60px;
                                    height: 60px;
                                    border-radius: 5px;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: cover;
                                    background-image: url('../../images/web/no-product.png');
                                    position: relative;
                                }
                                .product-content{
                                    flex: 1;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    margin-left: 15px;
                                    @media screen and (max-width: 575px){
                                        margin-left: 10px;
                                    }
                                    .product-info{
                                        margin-right: 10px;
                                        .product-category{
                                            font-size: 12px;
                                            color: #969696;
                                        }
                                    }
                                    .product-btn{
                                        width: 150px;
                                        min-width: 150px;
                                        max-width: 150px;
                                        @media screen and (max-width: 575px){
                                            width: 100px;
                                            min-width: 100px;
                                            max-width: 100px;
                                        }
                                        > button{
                                            border: 0;
                                            width: 100%;
                                            padding: 5px 15px;
                                            background-color: $main-color;
                                            color: #fff;
                                            font-size: 18px;
                                            font-weight: bold;
                                            border-radius: 50px;
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: center;
                                            @media screen and (max-width: 575px){
                                                font-size: 14px;
                                            }
                                            &::after{
                                                content: "\ff0b";
                                            }
                                        }
                                    }
                                }
                            }
                            .product-brands {
                                display: flex;
                                flex-wrap: wrap;
                                margin-top: 10px;
                                .badge{
                                    margin-right: 5px;
                                    margin-top: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal-box{
    &.product-light-box{
        .light-box{
            .light-box-header{

            }
            .light-box-body{
                .item-image{
                    border: 1px solid #f9f9f9;
                    width: 100px;
                    height: 100px;
                    border-radius: 5px;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    background-image: url('../../images/web/no-product.png');
                    position: relative;
                    &.sold-out{
                        &::after{
                            content: "Sold Out";
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            background-color: #e9509880;
                            color: #fff;
                            font-size: 16px;
                            font-weight: bold;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
                .item-info{
                    .item-category{
                        font-size: 12px;
                        color: #969696;
                    }
                    .item-name{
                        font-size: 14px;
                    }
                }
                .quantity-box{
                    display: flex;
                    align-items: center;
                    @media screen and (max-width: 575px){
                        font-size: 12px;
                    }
                    .quantity-btn{
                        background-color: transparent;
                        border: 1px solid rgba(0,0,0,0.15);
                        width: 32px;
                        min-width: 32px;
                        max-width: 32px;
                        height: 32px;
                        min-height: 32px;
                        max-height: 32px;
                        font-size: 18px;
                        line-height: 0;
                        padding: 0;
                        &:hover{
                            background-color: #f9f9f9;
                        }
                        &:disabled{
                            pointer-events: none;
                            color: #ccc;
                        }
                    }
                    .quantity-input{
                        border: 0;
                        border-radius: 0;
                        text-align: center;
                        width: 50px;
                        height: 32px;
                        font-size: 14px;
                        border-top: 1px solid rgba(0,0,0,0.15);
                        border-bottom: 1px solid rgba(0,0,0,0.15);
                        &:read-only{
                            color: #ccc;
                        }
                    }
                }
                // .quantity-btn{
                //     background-color: rgba(0,0,0,0.05);
                //     border-radius: 50%;
                //     border: 0;
                //     width: 25px;
                //     min-width: 25px;
                //     max-width: 25px;
                //     height: 25px;
                //     min-height: 25px;
                //     max-height: 25px;
                //     display: flex;
                //     justify-content: center;
                //     align-items: center;
                // }
                // .quantity-input{
                //     border: 0;
                //     text-align: center;
                //     width: 50px;
                // }
            }
            .light-box-footer{
                .add-to-cart-btn{
                    width: 100%;
                    background-color: $main-color;
                    color: #fff;
                    border-radius: 50px;
                    height: 40px;
                    &:disabled{
                        background-color: #cdcdcd;
                        color: #7a7a7a;
                        pointer-events: none;
                    }
                }
            }
        }
    }
}