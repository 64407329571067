.icon {
    display: inline-block;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    text-align: center;
    line-height: 22px;
    font-style: initial;
}

i{
    &.common-icon{
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 30px;
        display: block;
        &.icon-sm{
            width: 20px;
        }
        &.icon-lg{
            width: 38px;
        }
        &::after{
            content: "";
            display: block;
            padding-top: 100%;
        }
        &.robot{
            background-image: url('../../images/icon/robot.svg');
        }
        &.api-manage{
            background-image: url('../../images/icon/api_manage.svg');
        }
        &.guide{
            background-image: url('../../images/icon/guide.svg');
        }
        &.order{
            background-image: url('../../images/icon/order.svg');
        }
        &.sack-dollar{
            background-image: url('../../images/icon/sack_dollar.png');
        }
        &.key{
            background-image: url('../../images/icon/key.png');
        }
        &.log{
            background-image: url('../../images/icon/log.png');
        }
        &.fire{
            background-image: url('../../images/icon/fire.png');
        }
        &.idea{
            background-image: url('../../images/icon/idea.png');
        }
        &.wallet{
            background-image: url('../../images/icon/wallet.svg');
        }
        &.fuel{
            background-image: url('../../images/icon/fuel.png');
        }
        &.fuel2{
            background-image: url('../../images/icon/fuel2.png');
        }
        &.point{
            background-image: url('../../images/icon/point.png');
        }
        &.leaderboard{
            background-image: url('../../images/icon/leaderboard.png');
        }
        &.pen{
            background-image: url('../../images/icon/pen.png');
        }
        &.lock{
            background-image: url('../../images/icon/lock.png');
        }
        &.globe{
            background-image: url('../../images/icon/globe.png');
        }
        &.exit{
            background-image: url('../../images/icon/exit.png');
        }
        &.huobi{
            background-image: url('../../images/icon/huobi.png');
        }
        &.binance{
            background-image: url('../../images/icon/binance.png');
        }
        &.atom{
            background-image: url('../../images/icon/atom.png');
        }
        &.btc{
            background-image: url('../../images/icon/btc.png');
        }
        &.ai{
            background-image: url('../../images/icon/ai.png');
        }
        &.home{
            background-image: url('../../images/icon/home.png');
        }
        &.home{
            background-image: url('../../images/icon/home.png');
        }
        &.quantify{
            background-image: url('../../images/icon/quantify.png');
        }
        &.news{
            background-image: url('../../images/icon/news.png');
        }
        &.dashboard{
            background-image: url('../../images/icon/dashboard.png');
        }
        &.bid{
            background-image: url('../../images/icon/bid.png');
        }
        &.sort{
            background-image: url('../../images/icon/sort.png');
        }
        &.eth{
            background-image: url('../../images/icon/eth.png');
        }
        &.doge{
            background-image: url('../../images/icon/doge.png');
        }
        &.aave{
            background-image: url('../../images/icon/aave.png');
        }
        &.dot{
            background-image: url('../../images/icon/dot.png');
        }
        &.crv{
            background-image: url('../../images/icon/crv.png');
        }
        &.uni{
            background-image: url('../../images/icon/uni.png');
        }
        &.link{
            background-image: url('../../images/icon/link.png');
        }
        &.atom{
            background-image: url('../../images/icon/atom.png');
        }
        &.ltc{
            background-image: url('../../images/icon/ltc.png');
        }
        &.bch{
            background-image: url('../../images/icon/bch.png');
        }
        &.crown{
            background-image: url('../../images/icon/crown.png');
        }
        &.invite{
            background-image: url('../../images/icon/invite.svg');
        }
        &.friends{
            background-image: url('../../images/icon/friends.svg');
        }
        &.search{
            background-image: url('../../images/icon/search.png');
        }
        &.rank1{
            background-image: url('../../images/icon/rank1.svg');
        }
        &.rank2{
            background-image: url('../../images/icon/rank2.svg');
        }
        &.rank3{
            background-image: url('../../images/icon/rank3.svg');
        }
        &.yuanbao{
            background-image: url('../../images/icon/yuanbao.svg');
        }
        &.transfer{
            background-image: url('../../images/icon/transfer.svg');
        }
    }
}