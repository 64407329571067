.common-form{
    .form-container{
    }
}

.submit-btn {
    height: 48px;
    border-radius: 50px;
    cursor: pointer;
    width: 100%;
    color: #444;
    font-weight: bold;
    background-color: $main-color;
    color: #fff;
    padding: 0 12px;
    border: 0;
    &:disabled, &[disabled] {
        background-color: #cdcdcd;
        color: #7a7a7a;
        pointer-events: none;
    }
}

.form-group{
    margin: 0;
    &.dir-horizontal{
        display: flex;
        align-items: center;
        .label-group{
            margin-bottom: 0;
            width: 120px;
            min-width: 120px;
            max-width: 120px;
        }
    }
    & + .form-group{
        margin-top: 20px;
    }
    .label-group{
        margin-bottom: 8px;
        label{
            white-space: nowrap;
            font-size: 14px;
            font-weight: bold;
        }
    }
}
.text-group {
    // padding: 8px 0;
}

.custom-input-group{
    display: flex;
    flex: 1;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
    border-radius: 3px;
    transition: border 0.1s ease;
    &.error{
        border: 1px solid #dc3545 !important;
    }
    &.theme-outline{
        border: 1px solid rgba(0,0,0,0.1);
        background-color: #fff;
        &:focus-within{
            border: 1px solid rgba(0,0,0,0.25);
        }
    }
    &.theme-filled{
        background-color: rgba(0,0,0,0.05);
    }
    &.tiny{
        max-width: 100px;
    }
    .phone-code-select{
        max-width: 80px;
        margin-right: 10px;
    }
    > input{
        border: 0;
        flex: 1;
        width: 1px;
        padding: 12px 5px 12px 10px;
        color: #555;
        background-color: transparent;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &::placeholder{
            color: #aaa;
        }
        &:read-only{
            padding: 8px 0 8px 10px;
            color: #969696;
        }
    }
    select{
        width: 100%;
        border: 0;
        color: #555;
        // border-bottom: 1px solid #d9d9d9;
        font-size: 12px;
        cursor: pointer;
        padding: 11px 30px 11px 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='black' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
        &.phone-code-select{
            border-radius: 0;
            border-bottom: 1px solid #d9d9d9;
            max-width: 80px;
            background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='black' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
        }
        &.address_city_select{
            flex: 1;
        }
        &.address_district_select{
            flex: 1;
        }
        option{
            color: $text-dark;
        }
    }
}

textarea{
    border: 1px solid rgba(0,0,0,0.1);
    flex: 1;
    padding: 10px;
    border-radius: 3px;
    &::placeholder{
        color: #aaa;
    }
    &:read-only{
        background-color: #efefef;
        border: 1px solid #efefef;
    }
}

.form-desc{
    padding: 15px;
    border-radius: 10px;
    background-color: rgba(0,0,0,0.05);
    // background-color: #FFFAE2;
    font-size: 12px;
    .title{
        font-family: 微軟正黑體;
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 10px;
    }
}

.view-pass-btn{
    border: 0;
    background-color: transparent;
    padding: 5px 12px;
}

.verify-btn{
    height: 100%;
    border: 0;
    color: #555;
    margin-right: 10px;
    border-radius: 5px !important;
    border: 1px solid #555;
    padding: 5px 12px;
    white-space: nowrap;
    font-family: 微軟正黑體;
    &:focus{
        border: 1px solid #555 !important;
    }
    &:disabled{
        border: 1px solid #ccc;
        color: #aaa;
        pointer-events: none;
    }
}

//mui radio
.MuiFormControlLabel-root{
    // margin-left: 0 !important;
    // margin-right: 5px !important;
}
.MuiRadio-root{
    padding: 4px !important;
}
.MuiTypography-body1{
    font-size: 14px !important;
}