.avatar-popper{
    z-index: 1;
    padding: 5px 15px 0 15px;
    .avatar-container{
        background-color: #fff;
        border-radius: 2px;
        // border: 1px solid #ddd;
        width: 250px;
        box-shadow: 0 4px 12px 0 rgba(0,0,0,0.2);
        .popper-header{
            background-color: #fff;
            display: flex;
            align-items: center;
            width: 100%;
            padding: 15px;
            border-bottom: 1px solid #ddd;
            &::before{
                display: none;
            }
        }
        .popper-body{
            background-color: #fff;
            padding: 0;
            ul.popper-tools-list{
                padding: 8px 0;
                li.popper-tools-item{
                    padding: 10px 0;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    font-size: 14px;
                    &:hover{
                        background-color: rgba(0,0,0,0.05);
                    }
                    .popper-icon{
                        width: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        > i{
                            color: #909090;
                        }
                    }
                    .popper-label{
                        flex: 1;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.cart-popper{
    z-index: 1;
    padding: 5px 15px 0 15px;
    .cart-container{
        background-color: #fff;
        border-radius: 2px;
        width: 290px;
        box-shadow: 0 4px 12px 0 rgba(0,0,0,0.2);
        .popper-body{
            ul.cart-list{
                max-height: 300px;
                overflow-y: auto;
                padding: 0 15px;
                li.cart-item{
                    display: flex;
                    padding: 15px 0;
                    & + li.cart-item{
                        border-top: 1px solid #ddd;
                    }
                    .cart-image{
                        width: 50px;
                        height: 50px;
                        border-radius: 3px;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        background-image: url('../../images/web/no-product.png');
                    }
                    .cart-info{
                        font-size: 12px;
                        margin-left: 15px;
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        .category{
                            color: #969696;
                        }
                        .quantity{
                            font-weight: bold;
                            font-size: 14px;
                        }
                    }
                }
            }
            .cart-checkout-btn{
                border: 0;
                width: 100%;
                padding: 8px 0;
                border-radius: 4px;
                text-align: center;
                background-color: $main-color;
                color: #fff;
                display: block;
                font-size: 14px;
                font-weight: bold;
            }
        }
    }
}