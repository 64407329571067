.tooltip-button{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #969696;
    border: 0;
    background-color: transparent;
    &:hover{
        background-color: rgba(0,0,0,0.05);
        color: #555;
    }
    > i{
        color: inherit;
    }
    &.loading{
        pointer-events: none;
        color: #ccc;
    }
}