.MuiPagination-root{
    display: inline-block;
    .MuiPagination-ul{
        li{
            button{
                font-weight: bold;
                margin: 3px;
                border-radius: 5px;
                // color: #fff;
                @media screen and (max-width: 402px){
                    min-width: 26px;
                    height: 26px;
                    margin: 3px 2px;
                }
                &:hover{
                    background-color: rgba(0,0,0,0.05);
                    color: $text-dark;
                    // color: #fff;
                }
                &:focus{
                    outline: none;
                    color: $text-dark;
                    // color: #fff;
                }
            }
            .Mui-selected{
                // background-color:rgba(0, 0, 0, 0.05);
                background-color: $main-color;
                // color: #555;
                color: #fff;
                &:hover{
                    // background-color:rgba(0, 0, 0, 0.05);
                    background-color: $main-color;
                    // color: #555;
                    color: #fff;
                }
                &:focus{
                    outline: none;
                    // color: #555;
                    color: #fff;
                }
            }
        }
    }
}