.home-page{
    padding: 100px 0 30px 0;
    @media screen and (max-width: 575px){
        padding: 90px 0 30px 0;
    }
    .home-wrapper{
        width: 100%;
        max-width: 992px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        padding: 30px 30px 0 30px;
        @media screen and (max-width: 575px){
            padding: 30px 15px 0 15px;
        }
        .event-container{
            .event-content{
                overflow-x: auto;
                margin: 0 -30px;
                @media screen and (max-width: 575px){
                    margin: 0 -15px;
                }
            }
            ul.event-list{
                width: fit-content;
                display: flex;
                padding: 0 30px;
                @media screen and (max-width: 575px){
                    padding: 0 15px;
                }
                li.event-item{
                    width: 280px;
                    min-width: 280px;
                    max-width: 280px;
                    height: 280px;
                    font-size: 24px;
                    border-radius: 20px;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    color: #fff;
                    text-shadow: 0 0 1px #000;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 15px 30px;
                    cursor: pointer;
                    & + li.event-item{
                        margin-left: 30px;
                    }
                    @media screen and (max-width: 565px){
                        width: 210px;
                        min-width: 210px;
                        max-width: 210px;
                        height: 210px;
                        font-size: 20px;
                    }
                }
            }
        }
        .news-container{
            flex: 1;
            margin-top: 30px;
            ul.news-list{
                li.news-item{
                    min-height: 130px;
                    box-shadow: 0 0 5px rgba(0,0,0,0.1);
                    border-radius: 0 5px 5px 0;
                    border-left: 7px solid $main-color;
                    padding: 15px;
                    @media screen and (max-width: 575px){
                        min-height: unset;
                    }
                    & + li.news-item{
                        margin-top: 20px;
                    }
                    .time{
                        margin-bottom: 5px;
                        color: #969696;
                    }
                }
            }
        }
    }
}

.modal-box{
    &.events-light-box{
        .light-box{
            max-width: 1000px;
            width: calc(100% - 10px);
            figure{
                max-width: 100%;
            }
            img{
                max-width: 100%;
            }
        }
    }
}