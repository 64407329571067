.modal-box{
    .curtain-box{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 30px);
        max-width: 415px;
        max-height: 300px;
        border-radius: 15px;
        box-shadow: 0 0 5px rgba(0,0,0,0.2);
        transition: all 1s cubic-bezier(.2,1,.2,1);
        overflow: hidden;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        .curtain-box-body{
            overflow-y: scroll;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        .curtain-box-close{
            width: 30px;
            height: 30px;
            background-color: rgba(20, 16, 16, 0.2);
            position: absolute;
            top: 10px;
            right: 10px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &:hover{
                background-color: rgba(0,0,0,0.3);
            }
            &::before, &::after{
                content: "";
                position: absolute;
                width: 20px;
                height: 1.5px;
                background-color: #fff;
            }
            &::before{
                transform: rotate(45deg);
            }
            &::after{
                transform: rotate(135deg);
            }
        }
    }
}

.curtain-box-transition-enter{
    visibility: hidden !important;
    background-color: rgba(0,0,0,0) !important;
    .curtain-box{
        bottom: -100% !important;
    }
}
.curtain-box-transition-enter-active{
    visibility: visible !important;
    background-color: rgba(0,0,0,0.55) !important;
    transition: all 300ms ease-in-out !important;
    .curtain-box{
        bottom: 30px !important;
        transition: all 1000ms cubic-bezier(.2,1,.2,1) !important;
    }
}
.curtain-box-transition-enter-done{
    visibility: visible !important;
    background-color: rgba(0,0,0,0.55) !important;
    .curtain-box{
        bottom: 30px !important;
    }
}

.curtain-box-transition-exit{
    visibility: visible !important;
    background-color: rgba(0,0,0,0.55) !important;
    .curtain-box{
        bottom: 30px !important;
    }
}
.curtain-box-transition-exit-active{
    visibility: hidden !important;
    background-color: rgba(0,0,0,0) !important;
    transition: all 300ms ease-in-out !important;
    .curtain-box{
        bottom: -100% !important;
        transition: all 1000ms cubic-bezier(.2,1,.2,1) !important;
    }
}
.curtain-box-transition-exit-done{
    visibility: hidden !important;
    background-color: rgba(0,0,0,0) !important;
    .curtain-box{
        bottom: -100% !important;
    }
}

.modal-box{
    &.wallet-record-box{
        .curtain-box{
            .curtain-box-header{
                padding: 15px;
                font-family: 微軟正黑體;
                font-weight: bold;
            }
            .curtain-box-body{
                ul.curtain-box-list{
                    li.curtain-box-item{
                        padding: 10px 15px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        &:hover{
                            background-color: rgba(0,0,0,0.05);
                        }
                        &.active{
                            background-color: rgba(0,0,0,0.05);
                            .check{
                                background-color: #1976d2;
                                &::before{
                                    content: "\f00c";
                                    font-family: "Font Awesome 5 Pro";
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    color: #fff;
                                    font-size: 20px;
                                    font-weight: 200;
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                        .check{
                            background-color: rgba(0,0,0,0.1);
                            border-radius: 50%;
                            width: 36px;
                            height: 36px;
                            margin-right: 10px;
                        }
                        > img {
                            margin: 0 10px;
                            width: 36px;
                        }
                        > i {
                            width: 30px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #555;
                            font-size: 24px;
                        }
                        .text{
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
            }
            .curtain-box-footer{
                padding: 15px;
                text-align: right;
                > button {
                    margin-left: 10px;
                    border: 0;
                    background-color: transparent;
                }
            }
        }
    }
}