.history-page{
    padding-top: 100px;
    @media screen and (max-width: 575px){
        padding-top: 90px;
    }
    .history-container{
        width: 100%;
        max-width: 992px;
        margin: 0 auto;
        padding: 30px;
        @media screen and (max-width: 575px){
            padding: 30px 15px;
        }
        ul.history-list{
            li.history-item{
                padding: 15px 0;
                border-bottom: 1px solid #ddd;
            }
        }
    }
}


ul.order-product-list{
    &.view-mode-list{
        .quantity-box{
            display: flex;
            align-items: center;
            align-self: flex-end;
            @media screen and (max-width: 575px){
                font-size: 12px;
            }
            .quantity-btn{
                background-color: transparent;
                border: 1px solid rgba(0,0,0,0.15);
                width: 26px;
                min-width: 26px;
                max-width: 26px;
                height: 26px;
                min-height: 26px;
                max-height: 26px;
                font-size: 16px;
                line-height: 0;
                padding: 0;
                &:hover{
                    background-color: #f9f9f9;
                }
                &:disabled{
                    pointer-events: none;
                    color: #ccc;
                }
            }
            .quantity-input{
                border: 0;
                border-radius: 0;
                text-align: center;
                // width: 40px;
                width: 48px;
                height: 26px;
                font-size: 12px;
                border-top: 1px solid rgba(0,0,0,0.15);
                border-bottom: 1px solid rgba(0,0,0,0.15);
            }
        }
        li.order-product-item{
            display: flex;
            flex-direction: column;
            padding: 15px 0;
            & + li.order-product-item{
                border-top: 1px solid rgba(0,0,0,0.1);
            }
            .product-container{
                display: flex;
                align-items: center;
                .product-image{
                    width: 60px;
                    min-width: 60px;
                    max-width: 60px;
                    height: 60px;
                    border-radius: 5px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    background-image: url('../../images/web/no-product.png');
                    position: relative;
                }
                .product-content{
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-left: 15px;
                    @media screen and (max-width: 575px){
                        margin-left: 10px;
                    }
                    .product-info{
                        margin-right: 10px;
                        font-size: 14px;
                        @media screen and (max-width: 575px){
                            font-size: 12px;
                        }
                        // .product-category{
                        //     font-size: 12px;
                        //     color: #969696;
                        // }
                    }
                }
            }
            .product-btn{
                // width: 120px;
                min-width: 100px;
                // max-width: 120px;
                // @media screen and (max-width: 415px){
                //     width: 90px;
                //     min-width: 90px;
                //     max-width: 90px;
                // }
                > button{
                    border: 0;
                    width: 100%;
                    padding: 5px 10px;
                    background-color: $main-color;
                    color: #fff;
                    font-size: 13px;
                    font-weight: bold;
                    border-radius: 50px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    // @media screen and (max-width: 415px){
                    //     font-size: 12px;
                    // }
                    &::after{
                        margin-left: 10px;
                        content: "＋";
                    }
                }
            }
            .product-brands {
                display: flex;
                flex-wrap: wrap;
                margin-top: 10px;
                .badge{
                    margin-right: 5px;
                    margin-top: 5px;
                }
            }
        }
    }
}