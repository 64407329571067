.search-bar {
    position: relative;
    display: none;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 3px;
    overflow: hidden;
    align-items: center;
    &.active {
        display: flex;
        @media screen and (max-width: 575px) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #fff;
            z-index: 2;
            display: flex;
            align-items: center;
            > input {
                flex: 1 1;
                height: 100%;
                width: unset;
                font-size: unset;
            }
            > i, svg {
                margin: 0 5px 0 15px;
            }
            .close-search{
                padding: 0 15px;
                width: unset;
            }
        }
    }
    > i, svg {
        margin-left: 5px;
    }
    > input {
        width: 145px;
        border: none;
        padding: 8px 30px 8px 5px;
        font-size: 12px;
    }
    .close-search {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 100%;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}