.photo-modal{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,1);
    z-index: 1000;
    transform: translateX(100%);
    transition: all 0.3s ease-in-out;
    // display: flex;
    // flex-direction: column;
    .photo-modal-header{
        padding: 0 15px;
        height: 60px;
        min-height: 60px;
        max-height: 60px;
        background-color: rgba(255,255,255,0.1);
        // background-color: rgba(0,0,0,0.5);
        display: flex;
        align-items: center;
        position: relative;
        > i{
            color: #fff;
            font-size: 20px;
            cursor: pointer;
            padding: 5px;
        }
        .count{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            color: #fff;
            font-size: 20px;
            letter-spacing: 3px;
        }
    }
    .photo-slider{
        width: 100%;
        height: calc(100% - 60px);
        .swiper-container{
            height: 100%;
            .swiper-wrapper{
                height: 100%;
                .swiper-slide{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    > img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
            // .photo-item{
            //     max-width: 1024px;
            //     margin: 0 auto;
            //     background-repeat: no-repeat;
            //     background-size: contain;
            //     background-position: center;
            //     height: 100%;
            // }
        }
    }
    .photo-modal-footer{
        display: none;
        height: 75px;
        padding-bottom: 15px;
        background-color: rgba(255,255,255,0.1);
        color: #969696;
        line-height: 60px;
        text-align: center;
    }
}

.photoModal-transition-enter{
    transform: translateX(100%) !important;
}
.photoModal-transition-enter-active{
    transform: translateX(0) !important;
    transition: all 300ms ease-in-out !important;
}
.photoModal-transition-enter-done{
    transform: translateX(0) !important;
}

.photoModal-transition-exit{
    transform: translateX(0) !important;
}
.photoModal-transition-exit-active{
    transform: translateX(100%) !important;
    transition: all 300ms ease-in-out !important;
}
.photoModal-transition-exit-done{
    transform: translateX(100%) !important;
}