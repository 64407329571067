.select-box{
    padding: 3px 25px 3px 10px;
    background-color: rgba(0,0,0,0.05);
    border-radius: 30px;
    font-size: 14px;
    border: 0;
    position: relative;
    &.active{
        &::before{
            transform: translateY(-50%) rotate(180deg);
        }
    }
    &::before{
        content: "\f107";
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%) rotate(0);
        font-family: "Font Awesome 5 Pro";
        transition: all 0.15s ease-in-out;
    }
}