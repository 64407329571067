ul.tab-list{
    display: flex;
    position: relative;
    padding: 15px;
    li.tab-item + li.tab-item{
        margin-left: 10px;
    }
    li.tab-item{
        // cursor: pointer;
        border-radius: 50px;
        position: relative;
        z-index: 1;
        &.active{
            .tab-link{
                color: #023E8A;
            }
        }
        .tab-link{
            display: flex;
            align-items: center;
            font-weight: bold;
            padding: 0 10px;
            height: 30px;
            cursor: pointer;
        }
    }
    .active-box{
        background-color: #E8F0FB;
        border-radius: 50px;
        height: 30px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 0;
        transition: left 0.15s ease;
    }
    .tab-container{

    }
}