.user-footer{
    border-top: 1px solid rgba(0,0,0,0.05);
    width: 100%;
    max-width: 768px;
    height: 90px;
    position: fixed;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    background-color: #fff;
    z-index: 999;
    ul.footer-list{
        display: flex;
        height: 100%;
        li.footer-item{
            flex: 1;
            display: flex;
            cursor: pointer;
            > a, > div{
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                position: relative;
                padding-bottom: 15px;
                &.selected{
                    .center{
                        color: #023E8A;
                    }
                    i.footer-icon{
                        opacity: 1;
                    }
                }
                i{
                    font-size: 20px;
                    margin-bottom: 10px;
                }
                // &:active{
                //     background: linear-gradient(to bottom, rgba(0,0,0,0.05) 0%, rgba(0,0,0,0) 100%);
                // }
                i.footer-icon{
                    background-repeat: no-repeat;
                    background-size: 100%;
                    width: 26px;
                    height: 26px;
                    line-height: 24px;
                    margin-bottom: 5px;
                    text-align: center;
                    font-size: 24px;
                    opacity: 0.5;
                    &.home{
                        background-image: url('../../images/icon/home.png');
                    }
                    &.news{
                        background-image: url('../../images/icon/news.png');
                    }
                    &.quantify{
                        // width: 50px;
                        // height: 50px;
                        // margin-top: -24px;
                        // opacity: 1;
                        background-image: url('../../images/icon/quantify2.png');
                    }
                    &.bid{
                        background-image: url('../../images/icon/bid.png');
                    }
                    &.dashboard{
                        background-image: url('../../images/icon/dashboard.png');
                    }
                }
            }
        }
    }
}