.table-container{
    & + .table-container{
        margin: 30px auto 0 auto;
    }
    .search-bar{
        position: relative;
        margin-bottom: 20px;
        // padding: 5px 0;
        display: flex;
        flex-wrap: wrap;
        height: 50.8px;
        transition: max-height 0.5s ease-in-out;
        @media screen and (max-width: 650px) {
            max-height: 49px;
            height: unset;
            width: 100%;
            overflow: hidden;
        }
        &.active{
            max-height: 310.4px;
        }
        @media screen and (min-width: 645px){
            height: unset;
            overflow: hidden;
            &.active{
                height: unset;
            }
        }
        h6.search-title{
            position: relative;
            display: none;
            padding: 10px 15px;
            border-radius: 5px;
            @media screen and (max-width: 650px) {
                display: unset;
                width: 100%;
                cursor: pointer;
                background: #f3f3f3;
                
                &:after{
                    font-family: 'Font Awesome 5 Pro';
                    font-weight: 300;
                    content: "\f078";
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    transform: translateY(-50%);
                    transition: all 0.5s cubic-bezier(0.86, 0, 0.07, 1);
                }
                &.active{
                    &:after{
                        transform: translateY(-50%) rotate(180deg);
                    }
                }
            }
        }
        .search-input-group {
            position: relative;
            display: inline-block;
            min-width: 150px;
            // margin: 5px 0;
            @media screen and(max-width: 650px){
                width: 100%;
                margin-right: 0 !important;
            }
            &.keyword{
                // margin-left: 15px;
                @media screen and(max-width: 650px){
                    margin-left: 0px;
                    margin: 20px 0;
                }
            }
            &.dropdown{
                margin-left: 15px;
                @media screen and(max-width: 650px){
                    margin: 20px 0 0 0;
                }
            }
            &.long-input {
                min-width: 250px;
            }
            input.inputDate{
                width: 100%;
                min-height: 36px;
                border: 1px solid #d1d1d1;
                border-radius: 3px;
                transition: all 0.15s;
                padding: 3px 5px;
                position: relative;
                background-color: #fff;
                font-size: 13px;
                &:focus{
                    border: 1px solid $text-secondary;
                    outline: none;
                }
                &::placeholder{
                    color: $text-secondary;
                }
                @media screen and (max-width: 650px) {
                    width: 100%;
                }
                @media not all and (min-resolution:.001dpcm){
                    @supports (-webkit-appearance:none) and (stroke-color:transparent) {
                        &:before{
                            content: attr(data-text);
                            color: $text-secondary;
                            position: absolute;
                            left: 5px;
                        }
                    }
                }
            }
            input.searchInput{
                width: 100%;
                height: 36px;
                border: 1px solid #d1d1d1;
                border-radius: 3px;
                transition: all 0.15s;
                padding: 3px 5px;
                font-size: 13px;
                &:focus{
                    border: 1px solid $text-secondary;
                    outline: none;
                }
                &::placeholder{
                    color: $text-secondary;
                }
            }
            select.searchSelect{
                height: 42px;
                border: 1px solid #d1d1d1;
                transition: all 0.15s;
                cursor: pointer;
                font-size: 0.9rem;
                &:focus{
                    outline: none;
                    box-shadow: none;
                    border: 1px solid $text-secondary;
                }
                &::placeholder{
                    color: $text-secondary;
                }
            }
            .search-input-cross{
                width: 16px;
                height: 16px;
                background-color: rgba(0,0,0,0.45);
                border-radius: 50%;
                position: absolute;
                top: 50%;
                right: 5px;
                transform: translateY(-50%);
                cursor: pointer;
                &.date{
                    right: 30px;
                    @media not all and (min-resolution:.001dpcm){
                        @supports (-webkit-appearance:none) and (stroke-color:transparent) {
                            right: 5px;
                        }
                    }
                }
                &:hover{
                    background-color: rgba(0,0,0,0.55);
                }
                &:before, &:after{
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 8px;
                    height: 1px;
                    border-radius: 3px;
                    background-color: #fff;
                }
                &:before{
                    transform: translate(-50%,-50%) rotate(45deg);
                }
                &:after{
                    transform: translate(-50%,-50%) rotate(135deg);
                }
            }        
        }
        .search-btn{
            display: inline-block;
            margin-left: 8px;
            padding: 0 15px;
            appearance: none;
            -webkit-appearance: none;
            border: 0;
            // width: 42px;
            height: 36px;
            // background-color: rgba(0,0,0,0.55);
            background-color: #1976d2;
            border-radius: 5px;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            transition:  all 0.15s;
            color: #fff;
            font-size: 13px;
            @media screen and (max-width: 650px ){
                width: 100%;
                margin-left: 0;
                margin-bottom: 10px;
                // &:before{
                //     content: '搜尋' !important;
                //     font-family: unset !important;
                //     font-weight: unset !important;
                // }
            }
            &:before{
                content: '\f002';
                // font-size: 13px;
                // font-weight: 900;
                font-family: 'Font Awesome 5 Pro';
                color: #fff;
                margin-right: 5px;
            }
            &:hover{
                background-color: #186fc5;
            }
            &:focus{
                outline: none;
            }
        }
    }
    .onepageshow-select{
        width: 40px;
        padding: 0 5px;
        border-radius: 3px;
        cursor: pointer;
        background-color: #fff;
        border: 1px solid #d1d1d1;
    }
    .page-nav{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        flex-wrap: wrap;
        font-size: 14px;
        @media screen and (max-width: 800px){
            flex-direction: column;
            align-items: center;
        }
        input.goto-page{
            // border: 1px solid $text-secondary;
            border: 0;
            border-radius: 0;
            border-bottom: 1.5px solid #d1d1d1;
            width: 60px;
            padding: 1px 5px;
            cursor: pointer;
            text-align: center;
            cursor: text;
            &:focus{
                border-bottom: 1.5px solid $secondary-color;
                outline: none;
            }
        }
    }
}


.batch-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    position: relative;
    &::before{
        content: "";
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #ebebeb;
    }
}

.table-search-bar{
    padding: 0 30px;
    @media screen and (max-width: 575px){
        padding: 0 15px;
    }
}

.data-table{
    overflow-x: auto;
    .table-border {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            background-color: #ebebeb;
        }
        &.table-border-left {
            &::after {
                top: 0;
                left: -1px;
                width: 1px;
                height: 100%;
            }
        }
        &.table-border-right {
            &::after {
                top: 0;
                right: -1px;
                width: 1px;
                height: 100%;
            }
        }
        &.table-border-top {
            &::after {
                top: -1px;
                left: 0;
                width: 100%;
                height: 1px;
            }
        }
        &.table-border-bottom {
            &::after {
                bottom: -1px;
                left: 0;
                width: 100%;
                height: 1px;
            }
        }
    }

    table{
        border: 0;
        margin-bottom: 0;
        border-collapse: separate;
        border-spacing: 0;
        >thead{
            background-color: #fff;
            z-index: 2;
            transition: all 0.3s ease-in-out;
            >tr{
                >th{
                    position: relative;
                    transition: all 0.15s;
                    user-select: none;
                    white-space: nowrap;
                    padding: 12px 20px;
                    text-align: center;
                    border-bottom: 1px solid #dee2e6;
                    // border-top: 1px solid #ebebeb;
                    border-top: 0;
                    // border-bottom: 0;
                    color: #606060;
                    font-size: 12px;
                    font-family: 微軟正黑體;
                    font-weight: normal;
                    position: relative;
                    &::before{
                        content: "";
                        position: absolute;
                        top: -1px;
                        left: 0;
                        width: 100%;
                        height: 1px;
                        background-color: #ebebeb;
                    }
                    // &::after{
                    //     content: "";
                    //     position: absolute;
                    //     bottom: -1px;
                    //     left: 0;
                    //     width: 100%;
                    //     height: 1px;
                    //     background-color: #ebebeb;
                    // }
                    &:first-child{
                        // border-left: 1px solid #ebebeb;
                        // border-top-left-radius: 7px;
                    }
                    &:last-child{
                        // border-right: 1px solid #ebebeb;
                        // border-top-right-radius: 7px;
                    }
                    &.order{
                        cursor: pointer;
                        &:hover{
                            background-color: rgba(0,0,0,0.05);
                        }
                        i{
                            position: absolute;
                            top: 50%;
                            right: 5px;
                            transform: translateY(-50%);
                            font-size: 12px;
                            color: #bebebe;
                        }
                    }
                }
            }
        }
        >tbody{
            background-color: #fff;
            >tr{
                &:nth-of-type(odd){
                    background-color: transparent;
                }
                &:hover{
                    background-color: rgba(0,0,0,0.03);
                }
                >td{
                    font-size: 13px;
                    color: #0d0d0d;
                    position: relative;
                    white-space: nowrap;
                    text-align: center;
                    vertical-align: middle;
                    border-top: 0;
                    border-bottom: 1px solid #dee2e6;
                    padding: 10px 15px;
                    &:first-child{
                        // border-left: 1px solid #ebebeb;
                    }
                    &:last-child{
                        // border-right: 1px solid #ebebeb;
                    }
                }
                &:last-child{
                    > td{
                        // border-bottom: 1px solid #ebebeb;
                        &:first-child{
                            // border-bottom-left-radius: 7px;
                        }
                        &:last-child{
                            // border-bottom-right-radius: 7px;
                        }
                    }
                }
            }
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"]{
    -moz-appearance: textfield;
}