header.main-header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    .nav-bar{
        width: 100%;
        height: 70px;
        background-color: #fff;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        padding: 0 30px;
        position: relative;
        @media screen and (max-width: 575px){
            height: 60px;
            padding: 0 15px;
        }
        &.open{
            @media screen and (max-width: 991px){
                .nav-container{
                    visibility: visible;
                    background-color: rgba(0,0,0,0.7);
                    .nav-menu{
                        // right: 0;
                        transform: translateX(-280px);
                    }
                }
            }
        }
        &::after{
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: 0;
            left: 0;
            background-color: rgba(0,0,0,0.1);
        }
        .logo-container{
            display: flex;
            align-items: center;
            .logo-image{
                width: 220px;
                @media screen and (max-width: 767px){
                    width: 190px;
                }
                @media screen and (max-width: 575px){
                    width: 160px;
                }
                @media screen and (max-width: 374px){
                    width: 110px;
                }
            }
        }
        .nav-container{
            flex: 1;
            background-color: transparent;
            position: relative;
            @media screen and (max-width: 991px){
                position: fixed;
                z-index: 2000;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                visibility: hidden;
                transition: all 0.5s ease;
            }
            .nav-menu{
                height: 100%;
                display: flex;
                flex-direction: column;
                @media screen and (max-width: 991px){
                    width: 280px;
                    height: 100%;
                    position: absolute;
                    right: -280px;
                    top: 0;
                    background-color: #fff;
                    transition: transform 0.5s ease;
                }
                .menu-head{
                    width: 100%;
                    height: 60px;
                    display: none;
                    @media screen and (max-width: 991px){
                        display: flex;
                    }
                    .menu-close{
                        width: 60px;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        position: relative;
                        margin-left: auto;
                        &::before, &::after{
                            content: "";
                            position: absolute;
                            width: 20px;
                            height: 1.5px;
                            background-color: #444;
                        }
                        &::before{
                            transform: rotate(45deg);
                        }
                        &::after{
                            transform: rotate(135deg);
                        }
                    }
                }
                .menu-content{
                    display: flex;
                    height: 100%;
                    padding: 0 15px;
                    flex: 1;
                    @media screen and (max-width: 991px){
                        padding: 0;
                        overflow-y: auto;
                        overflow-x: hidden;
                        flex-direction: column;
                    }
                    ul.nav-list{
                        display: flex;
                        align-items: center;
                        @media screen and (max-width: 991px){
                            flex-direction: column;
                            &:first-child{
                                margin-right: unset;
                            }
                        }
                        li.nav-item{
                            cursor: pointer;
                            position: relative;
                            height: 100%;
                            &.active{
                                .nav-label{
                                    color: $main-color;
                                }
                                &::before{
                                    content: "";
                                    position: absolute;
                                    bottom: 0;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    width: 80%;
                                    height: 2px;
                                    background-color: $main-color;
                                    transition: all 1s ease-in-out;
                                    @media screen and (max-width: 991px){
                                        display: none;
                                    }
                                }
                            }
                            &:hover{
                                .unit-box{
                                    display: block;
                                }
                            }
                            @media screen and (max-width: 991px){
                                width: 100%;
                                color: #444;
                                div.nav-label{
                                    &.active{
                                        &::after{
                                            transform: translateY(-50%) rotate(180deg);
                                        }
                                    }
                                    &::after{
                                        content: "\f078";
                                        font-family: "Font Awesome 5 Pro";
                                        font-weight: normal;
                                        font-size: 14px;
                                        position: absolute;
                                        top: 50%;
                                        right: 0;
                                        transform: translateY(-50%) rotate(0deg);
                                        transition: transform 0.3s ease-in-out;
                                        width: 60px;
                                        height: 60px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                    }
                                }
                            }
                            .nav-label{
                                color: inherit;
                                display: flex;
                                align-items: center;
                                padding: 0 25px;
                                height: 100%;
                                font-size: 15px;
                                font-weight: bold;
                                position: relative;
                                &:hover{
                                    color: $main-color;
                                }
                                @media screen and (max-width: 991px){
                                    color: $main-color;
                                    height: 60px;
                                    position: relative;
                                    &::after{
                                        color: #444;
                                    }
                                    &:hover{
                                        background-color: #f9f9f9;
                                    }
                                }
                            }
                            .unit-box{
                                min-width: 150px;
                                padding: 0 10px;
                                position: absolute;
                                top: calc(100% - 5px);
                                left: 50%;
                                transform: translateX(-50%);
                                background-color: $main-color;
                                color: #fff;
                                z-index: 1;
                                border-radius: 5px;
                                padding: 10px 0;
                                display: none;
                                box-shadow: 1px 2px 3px rgba(0,0,0,0.2);
                                @media screen and (max-width: 991px){
                                    position: initial;
                                    transform: translateX(0);
                                    border: 0;
                                    min-width: initial;
                                    width: 100%;
                                    padding: 0;
                                    border-radius: 0;
                                    display: block;
                                    background-color: transparent;
                                    color: #444;
                                    box-shadow: none;
                                }
                                .MuiCollapse-root{
                                    @media screen and (min-width: 992px){
                                        height: unset !important;
                                        visibility: visible !important;
                                    }
                                }
                                ul.unit-list{
                                    li.unit-item{
                                        &:hover{
                                            background-color: rgba(0,0,0,0.05);
                                        }
                                        .unit-link{
                                            text-align: center;
                                            display: block;
                                            padding: 8px 16px;
                                            white-space: nowrap;
                                            @media screen and (max-width: 991px){
                                                padding: 0;
                                                padding-left: 30px;
                                                height: 40px;
                                                display: flex;
                                                justify-content: flex-start;
                                                align-items: center;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .notification-btn{
            width: 40px;
            height: 40px;
            align-self: center;
        }
        .hamburger-box{
            display: none;
            @media screen and (max-width: 991px){
                display: block;
            }
        }
    }
}